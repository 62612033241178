<script>

import Item from "@/views/finance/enrollment/payment-progress/components/Item.vue";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  components: {ListTemplate, Item},
	data() {
		return {
			OSS_URL: process.env["VUE_APP_URL_OSS"],
			tableData: [],
			tableConfig: [
				{prop: 'pay_state', label: '支付状态'},
				{prop: 'order_m', label: '支付订单号'},
				{prop: 'money', label: '支付金额'},
				{prop: 'time_pay', label: '支付时间'},
				{prop: 'pay_mode', label: '付款方式'}
			],
			form: {}
		}
	},
	methods: {},

	created() {
		this.$_register.get('api/recruit-v2/pay/order-detail?order_id=' + this.$route.query.id).then(res => {
			this.form = res.data.data;
		})
		this.$_register.get('api/recruit-v2/pay/order-pay-list?order_id=' + this.$route.query.id).then(res => {
			this.tableData = res.data.data;
		})
	}
}
</script>
